import { defineStore } from 'pinia'
import axios from 'axios';
import api from '@/utils/api';
import { API_BASE_URL } from '@/apiConfig';

export const useUserStore = defineStore('user', {
  state: () => ({
    jwtToken: '',
    user: null,
    isAdmin: false,
    loginTime: null
  }),
  getters: {
    isLoggedIn: (state) => {
      if (!state.jwtToken) return false;
      try {
        const tokenPayload = JSON.parse(atob(state.jwtToken.split('.')[1]));
        const expirationTime = tokenPayload.exp * 1000; // 转换为毫秒
        return Date.now() < expirationTime;
      } catch (error) {
        console.error('解析JWT令牌时出错:', error);
        return false; // 如果解析出错，认为未登录
      }
    }
  },
  actions: {
    loadUserData() {
      const jwtToken = localStorage.getItem('jwtToken');
      const user = JSON.parse(localStorage.getItem('user'));
      const isAdmin = localStorage.getItem('isAdmin') === 'true';
      const loginTime = localStorage.getItem('loginTime');

      if (jwtToken) {
        this.jwtToken = jwtToken;
        this.user = user;
        this.isAdmin = isAdmin;
        this.loginTime = loginTime;
        api.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
      }
    },
    setToken(token) {
      this.jwtToken = token;
      localStorage.setItem('jwtToken', token);
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    setUser(user) {
      this.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    setAdmin(isAdmin) {
      this.isAdmin = isAdmin;
      localStorage.setItem('isAdmin', isAdmin);
    },
    setLoginTime() {
      const now = new Date().toISOString();
      this.loginTime = now;
      localStorage.setItem('loginTime', now);
    },
    clearUserData() {
      this.jwtToken = '';
      this.user = null;
      this.isAdmin = false;
      this.loginTime = null;
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('user');
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('loginTime');
      delete api.defaults.headers.common['Authorization'];
    },
    async login(email, didToken) {
      try {
        const response = await axios.post(`${API_BASE_URL}/login`, { email }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${didToken}`
          }
        });
        const data = response.data;
        this.setToken(data.token);
        this.setUser(data.user);
        this.setAdmin(data.is_admin);
        this.setLoginTime();
        
        return {
          authenticated: true,
          isAdmin: data.is_admin,
          user: data.user
        };
      } catch (error) {
        console.error('登录失败:', error);
        return {
          authenticated: false,
          isAdmin: false,
          user: null
        };
      }
    },
    logout() {
      this.clearUserData();
    },
    checkNewToken(headers) {
      const newToken = headers['new-token'];
      if (newToken) {
        this.setToken(newToken);
        this.setLoginTime(); // 更新登录时间
      }
    },
  }
});