<template>
  <div v-if="!isSubmitted" class="sell-page">
    <div style="text-align: center; margin-bottom: 20px">
      <h1 style="font-size: 28px; font-weight: bold; color: #333">
        {{ $t("message.sellUSDT") }}
      </h1>
      <p style="font-size: 18px; color: #666">{{ $t("message.USDTToHKD") }}</p>
    </div>

    <!-- 表单部分 -->
    <el-form :model="form" label-width="100px">
      <el-card class="box-card" style="margin-bottom: 20px">
        <template #header>
          <div class="card-header" style="text-align: left">
            <span>{{ $t("message.amountInput") }}</span>
          </div>
        </template>

        <el-form-item :label="$t('message.usdtAmount')">
          <el-input
            v-model.number="form.usdtAmount"
            @input="calculateHKD"
            :placeholder="$t('message.enterUSDT')"
            style="width: 100%"
          >
            <template #append>
              <div style="width: 50px; text-align: center">USDT</div>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('message.amount')">
          <el-input
            v-model.number="form.amount"
            :placeholder="$t('message.amount')"
            style="width: 100%"
          >
            <template #append>
              <div style="width: 50px; text-align: center">HKD</div>
            </template>
          </el-input>

          <div style="display: flex; flex-direction: column">
            <div
              v-if="form.amount % 100 !== 0 || form.amount < minAmount"
              class="error-message"
            >
              {{ t("message.amountHint") }}
            </div>

            <!-- 自动调整金额按钮 -->
            <el-button
              type="success"
              @click="adjustHKD"
              :style="{
                display: 'block', 
                marginTop: (form.amount % 100 !== 0 || form.amount < minAmount) ? '0' : '10px'
              }"
            >
              {{ t("message.adjustAmount") }}
            </el-button>
          </div>
        </el-form-item>
      </el-card>

      <el-form-item :label="t('message.usdtAmount')">
        <div class="usdt-amount">
          {{ formatNumberWithCommas(usdtAmount) }}
          <img
            src="@/assets/usdt_logo.svg"
            alt="USDT"
            style="width: 30px; height: 30px; margin-left: 5px"
          />
        </div>
      </el-form-item>

      <el-form-item :label="$t('message.exchangeRate')">
        <div style="display: flex; align-items: center">
          <el-button v-if="allowAdjust" @click="decreaseExchangeRate"
            >-</el-button
          >
          <el-input
            v-model="exchangeRate"
            disabled
            style="width: 100px; text-align: center"
          ></el-input>
          <el-button v-if="allowAdjust" @click="increaseExchangeRate"
            >+</el-button
          >
        </div>
      </el-form-item>

      <el-form-item :label="t('message.amount')">
        <div class="usdt-amount">
          {{ formatNumberWithCommasNoDecimals(form.amount) }}
          <img
            src="@/assets/hkd.png"
            alt="HKD"
            style="width: 30px; height: 30px; margin-left: 5px"
          />
        </div>
      </el-form-item>

      <el-form-item :label="$t('message.chain')">
        <el-select
          v-model="form.chain"
          @change="handleChainChange"
          placeholder="Select Chain"
        >
          <el-option label="Tron / TRC20" value="tron"></el-option>
          <el-option
            label="Binance Smart Chain / BEP20"
            value="bsc"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('message.walletAddress')">
        <el-input v-model="form.walletAddress" disabled></el-input>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="submitForm" style="width: 100%">{{
      $t("message.submit")
    }}</el-button>
  </div>

  <!-- 提交后的显示部分 -->
  <div v-else class="submission-result">
    <p>{{ t("message.orderNumber") }}: {{ orderNumber }}</p>
    <p>
      {{ t("message.amount") }}:
      {{ formatNumberWithCommasNoDecimals(form.amount) }} HKD
    </p>
    <p style="font-size: 36px">
      {{ t("message.usdtAmount") }}:
      {{ formatNumberWithCommas(usdtAmount) }} USDT
    </p>
    <p>{{ exchangeRate }} HKD/USDT</p>
    <p>{{ t("message.walletAddress") }}: {{ form.walletAddress }}</p>
    <!-- 新增二维码显示 -->
    <qrcode-vue :value="form.walletAddress" :size="200"></qrcode-vue>

    <!-- 增加查看收据按钮 -->
    <el-button type="primary" @click="viewReceipt">{{
      t("message.viewReceipt")
    }}</el-button>

    <el-button type="primary" @click="goToHome">{{
      t("message.returnToHome")
    }}</el-button>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import QrcodeVue from "vue-qrcode";

import {
  formatNumberWithCommas,
  formatNumberWithCommasNoDecimals,
} from "@/utils/formatNumber";
import { API_BASE_URL, PRICE_API_URL } from "@/apiConfig";
import generateOrderId from "@/utils/generateOrderId";
import { useRouter } from "vue-router"; // 导入 useRouter

export default {
  components: {
    QrcodeVue,
  },
  props: {
    allowAdjust: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter(); // 使用 useRouter
    const { t } = useI18n();

    const minAmount = ref(process.env.VUE_APP_MIN_AMOUNT || 1000);

    const form = ref({
      amount: "",
      usdtAmount: "", // 新增 USDT 输入值
      walletAddress: "TY2QXns2vf988JrxvKg5rpvcvpxWY88888",
      chain: "tron",
    });

    const exchangeRate = ref(null);
    const usdtAmount = ref("");
    const isSubmitted = ref(false);
    const orderNumber = ref("");
    const paymentAddress = ref("");

    const calculateUSDT = () => {
      if (exchangeRate.value && form.value.amount > 0) {
        usdtAmount.value = (form.value.amount / exchangeRate.value).toFixed(4);
      } else {
        usdtAmount.value = "";
      }
    };

    const calculateHKD = () => {
      if (exchangeRate.value && form.value.usdtAmount > 0) {
        form.value.amount = (
          form.value.usdtAmount * exchangeRate.value
        ).toFixed(2);
      }
    };

    const adjustHKD = () => {
      if (form.value.amount % 100 !== 0) {
        // 将港币金额调整为最近的 100 的倍数
        form.value.amount = Math.round(form.value.amount / 100) * 100;
        // 根据调整后的港币金额重新计算USDT
        calculateUSDT();
      }
    };

    watch([() => form.value.amount, exchangeRate], calculateUSDT);

    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get(PRICE_API_URL);
        const data = response.data;

        exchangeRate.value = data.sellPrice;
        console.log("当前买入价格:", data.buyPrice);
        console.log("当前卖出价格:", data.sellPrice);
        console.log("更新时间:", data.updateTime);
        console.log("其他信息:", data.otherInfo);
      } catch (error) {
        console.error("获取汇率失败:", error);
        ElMessage.error(t("message.exchangeRateError"));
      }
    };

    const handleChainChange = (value) => {
      if (value === "tron") {
        form.value.walletAddress = "TY2QXns2vf988JrxvKg5rpvcvpxWY88888";
      } else if (value === "bsc") {
        form.value.walletAddress = "0x7a543874a5879152bbe95c7e3dfe17ee0d3cc59e";
      }
    };

    const validateForm = () => {
      // 只验证金额，必须是100的倍数，且至少为3000
      if (
        form.value.amount % 100 !== 0 ||
        form.value.amount < minAmount.value
      ) {
        ElMessage.error(t("message.amountError"));
        return false;
      }
      return true;
    };

    // const submitForm = async () => {
    //   // 验证表单数据
    //   if (!validateForm()) return

    //   // 生成随机订单号
    //   orderNumber.value = generateOrderId();
    //   paymentAddress.value = form.value.walletAddress

    //   try {
    //     // 提交订单到后端 API
    //     await axios.post(`${API_BASE_URL}/preorder`, {
    //       order_number: orderNumber.value,
    //       amount: form.value.amount,
    //       usdt_amount: usdtAmount.value,
    //       exchange_rate: exchangeRate.value,
    //       receive_address: form.value.walletAddress,
    //       chain_type: form.value.chain,
    //       order_type: 'sell'
    //     })

    //     ElMessage.success(t('message.orderSubmitted'))
    //     isSubmitted.value = true
    //   } catch (error) {
    //     console.error('订单提交失败:', error)
    //     ElMessage.error(t('message.submitFailed'))
    //   }
    // }

    // 增加汇率调整功能
    const increaseExchangeRate = () => {
      exchangeRate.value = (parseFloat(exchangeRate.value) + 0.001).toFixed(4); // 每次增加 0.001
      calculateUSDT();
    };

    const decreaseExchangeRate = () => {
      exchangeRate.value = (parseFloat(exchangeRate.value) - 0.001).toFixed(4); // 每次减少 0.001
      calculateUSDT();
    };

    const submitForm = async () => {
      if (!validateForm()) return;

      orderNumber.value = generateOrderId();

      const formData = new FormData();
      formData.append("order_number", orderNumber.value);
      formData.append("amount", form.value.amount);
      formData.append("usdt_amount", usdtAmount.value);
      formData.append("exchange_rate", exchangeRate.value);
      formData.append("receive_address", form.value.walletAddress);
      formData.append("chain_type", form.value.chain);
      formData.append("order_type", "sell");

      try {
        await axios.post(`${API_BASE_URL}/preorder`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        ElMessage.success(t("message.orderSubmitted"));
        isSubmitted.value = true;
      } catch (error) {
        console.error("订单提交失败:", error);
        ElMessage.error(t("message.submitFailed"));
      }
    };

    const copyToClipboard = () => {
      navigator.clipboard
        .writeText(paymentAddress.value)
        .then(() => {
          ElMessage.success(t("message.addressCopied"));
        })
        .catch(() => {
          ElMessage.error(t("message.copyFailed"));
        });
    };

    const resetForm = () => {
      form.value = {
        amount: "",
        usdtAmount: "", // 重置 USDT 数量
        walletAddress: "TY2QXns2vf988JrxvKg5rpvcvpxWY88888",
        chain: "tron",
      };
      usdtAmount.value = "";
      isSubmitted.value = false;
    };

    onMounted(() => {
      console.log("props:", props.allowAdjust);
      fetchExchangeRate();
    });

    const viewReceipt = () => {
      router.push({
        name: "ReceiptSell",
        params: {
          orderNumber: orderNumber.value, // 将订单号传递到收据页面
        },
      });
    };

    return {
      form,
      exchangeRate,
      usdtAmount,
      isSubmitted,
      orderNumber,
      paymentAddress,
      calculateUSDT,
      calculateHKD,
      adjustHKD,
      submitForm,
      handleChainChange,
      copyToClipboard,
      resetForm,
      t,
      formatNumberWithCommas,
      formatNumberWithCommasNoDecimals,
      viewReceipt,
      minAmount,
      increaseExchangeRate,
      decreaseExchangeRate,
    };
  },
};
</script>


<style>
.error-message {
  color: red;
  margin-top: 5px;
  font-size: 12px;
}

.usdt-amount {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}

.submission-result {
  margin-top: 20px;
}

.submission-result p {
  font-size: 16px;
  margin-bottom: 10px;
}

.el-button {
  margin-left: 10px;
}

.usdt-amount {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}

.submission-result {
  margin-top: 20px;
}

.submission-result p {
  font-size: 16px;
}

.sell-page {
  margin: 20px;
}

.sell-page .el-button {
  margin-left: 0px;
}
</style>
