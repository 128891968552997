// src/utils/generateOrderId.js
// let lastTimestamp = 0
// let sequence = 0

// function generateOrderId() {
//   const timestamp = Date.now() // 使用当前时间戳，精确到毫秒

//   // 如果当前时间戳和上次的一样，则自增序列
//   if (timestamp === lastTimestamp) {
//     sequence++
//   } else {
//     sequence = 0
//     lastTimestamp = timestamp
//   }

//   // 拼接时间戳和序列号，确保唯一性，输出纯数字ID
//   const orderId = `${timestamp}${sequence.toString().padStart(3, '0')}`

//   return orderId
// }




// src/utils/generateOrderId.js
let lastTimestamp = 0
let sequence = 0

function generateOrderId() {
  // 获取秒级时间戳，并截取最后6位
  const timestamp = Math.floor(Date.now() / 1000).toString().slice(-6)

  // 如果当前时间戳和上次的一样，则自增序列
  if (timestamp === lastTimestamp) {
    sequence++
  } else {
    sequence = 0
    lastTimestamp = timestamp
  }

  // 序列号转换为字符串，并限制长度为2位
  const sequenceStr = sequence.toString().padStart(2, '8')

  // 计算需要的随机数位数来保持总长度为12位
  const randomLength = 12 - timestamp.length - sequenceStr.length
  const randomStr = Math.floor(Math.random() * Math.pow(10, randomLength)).toString().padStart(randomLength, '0')

  // 拼接时间戳、随机数和序列号，确保总长度为12位
  const orderId = `${timestamp}${sequenceStr}${randomStr}`

  return orderId
}

export default generateOrderId