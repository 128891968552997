export default {
    message: {
        language: 'Language',
        title: 'Rice Cryptocurrency Exchange',
        type: 'Transaction Type',
        buy: 'Buy',
        sell: 'Sell',
        amount: 'HKD Amount',
        submit: 'Submit',
        amountError: 'Amount must be a multiple of 100 and at least 3000 HKD',
        exchangeRateError: 'Failed to fetch exchange rate, please try again later',
        walletAddress: 'Wallet Address',
        enterWalletAddress: 'Enter your wallet address',
        chain: 'Chain Name',
        exchangeRate: 'Exchange Rate',
        amountHint: 'The amount must be a multiple of 100, with a minimum of 1000 HKD',
        usdtAmount: 'USDT Amount',
        orderNumber: 'Order Number',
        paymentAddress: 'Payment Address',
        copyAddress: 'Copy Address',
        copyFailed: 'Copy failed',
        addressCopied: 'Address copied successfully',
        reset: 'Order Again',
        returnToHome: 'Return to Home',
        walletAddressError: 'Wallet address is required',
        invalidTronAddress: 'Invalid Tron wallet address',
        invalidBscAddress: 'Invalid Binance Smart Chain (BSC) wallet address',
        orderSubmitted: 'Order submitted successfully!',
        submitFailed: 'Failed to submit the order.',
        adjustAmount: 'Adjust Amount',
        enterUSDT: 'Enter USDT Amount',
        viewReceipt: 'View Receipt',
        pendingOrders: "Pending Orders",
        noPendingOrders: "No pending orders",
        statusPending: "Pending",
        statusCompleted: "Completed",
        statusClosed: "Closed",
        edit: "Edit",
        markCompleted: "Mark as Completed",
        closeOrder: "Close Order",
        resetOrder: "Reset Order",
        uploadImage: "Take a Photo",
        receiptTitle: 'Rice Cryptocurrency Exchange',
        orderTime: 'Order Creation Date',
        printTime: 'Receipt Print Time',
        receiptDeclaration: 'Receipt Declaration',
        receiptDeclarationContent: 'I have verified the transaction details and payment information above and confirmed their accuracy. Once the Rice shop transfers USDT to the address provided, any subsequent changes in the USDT at this address will be solely your responsibility. Please ensure the security of your wallet address to avoid any potential loss of funds. Additionally, please confirm that you fully understand and acknowledge that this purchase is made by you and that you take full responsibility for this action.',
        printReceipt: 'Print Receipt',
        orderType: "Transaction Type",
        buyOrder: "Buy",
        sellOrder: "Sell",
        storeAddress: "Store Address",
        storeAddressContent: "Shop 150A, 1st Floor, President Commercial Centre, 608 Nathan Road, Mongkok, Kowloon, Hong Kong",
        sellReceiptDeclarationContent: 'I have verified the above transaction details and receipt information and confirm that they are accurate. I have received the due cash and confirm that the exchange between USDT and cash is fully settled. Please confirm that this transaction is conducted solely by you in person within the store. Please note that after this transaction is confirmed on-site, Rice is not responsible for any issues once you leave the premises.',
        startScanningError: 'Error occurred while starting scanning',
        deviceNotFound: 'Requested device not found',
        cameraNotSupported: 'Your device does not support camera functionality',
        scanningError: 'An error occurred during scanning',
        startScanning: 'Start Scanning',
        stopScanning: 'Stop Scanning',
        dashboard: 'Dashboard',
        todayTransactions: "Today's Transactions",
        todayProfit: "Today's Profit",
        completedOrders: 'Completed Orders',
        viewPendingOrders: 'View Pending Orders',
        status: 'Status',
        selectStatus: 'Select Status',
        startTime: 'Start Time',
        endTime: 'End Time',
        startDate: 'Start Date',
        endDate: 'End Date',
        search: 'Search',
        settings: 'Settings',
        buyPrice: 'Buy Price',
        sellPrice: 'Sell Price',
        setQuotes: 'Set Quotes',
        save: 'Save',
        memberLogin: 'Member Login',
        email: 'Email',
        password: 'Password',
        login: 'Login',
        memberPage: 'Member Page',
        welcome: 'Welcome',
        sendCode: 'Send Verification Code',
        verificationCode: 'Verification Code',
        buyUSDT: 'Buy USDT',
        hkdToUSDT: 'HKD to USDT',
        orderManagement: 'Order Management',
        saveSuccess: 'Save Success',
        logout: 'Logout',
        loading: 'Loading...',
        receiveAddress: 'Receive Address',
        createdAt: 'Created At',
        fee: 'Fee',
        transactionHash: 'Transaction Hash',
        remark: 'Remark',
        sendAddress: 'Send Address',
        all: 'All',
        amountInput: 'Please enter USDT amount or HKD amount',
        sellUSDT: 'Sell USDT',
        USDTToHKD: 'USDT To HKD',
    }
}