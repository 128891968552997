export const formatNumberWithCommas = (number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 4, // 保留4位小数
    maximumFractionDigits: 4
  }).format(number)
}

export const formatNumberWithCommasTwoDecimals = (number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2, // 保留2位小数
    maximumFractionDigits: 2
  }).format(number)
}
  
  export const formatNumberWithCommasNoDecimals = (number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0, // 保留0位小数
      maximumFractionDigits: 0
    }).format(number)
  }