<template>
  <div class="dashboard-container">
    <el-row>
      <el-col :span="4">
        <SidebarMenu />
      </el-col>
      <el-col :span="20">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 20px;">
          <h1>{{ t('message.dashboard') }}</h1>
          <div v-if="stats.length > 0" style="width: 100%;">
            <el-row :gutter="20" justify="center">
              <el-col :span="6" v-for="(stat, index) in stats" :key="index">
                <el-card class="box-card">
                  <template #header>
                    <div class="clearfix">
                      <span>{{ t(`message.${stat.key}`) }}</span>
                    </div>
                  </template>
                  <div class="card-content">
                    {{ formatStatValue(stat) }}
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div v-else style="text-align: center;">
            {{ t('message.loadingDashboardData') }}
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';
import api from '@/utils/api';
import { ElMessage } from 'element-plus';
import SidebarMenu from '@/components/SidebarMenu.vue';

import { formatNumberWithCommasTwoDecimals } from '@/utils/formatNumber';

export default {
  name: 'DashboardPage',
  components: {
    SidebarMenu
  },
  setup() {
    console.log('Dashboard setup function started');
    const { t } = useI18n();
    const router = useRouter();
    const userStore = useUserStore();
    const stats = ref([]);

    console.log('User store state:', userStore.$state);

    if (!userStore.isLoggedIn) {
      console.log('User not logged in, redirecting to login page');
      router.push('/login');
      return {};
    }

    const formatStatValue = (stat) => {
      if (stat.key === 'todayTransactions' || stat.key === 'todayProfit') {
        return formatNumberWithCommasTwoDecimals(stat.value);
      }
      return stat.value;
    };

    const fetchDashboardData = async () => {
      console.log('Fetching dashboard data');
      try {
        const response = await api.get('/dashboard');
        console.log('Dashboard API response:', response.data);
        const data = response.data;
        stats.value = [
          { key: 'todayTransactions', value: data.todayTransactions },
          { key: 'todayProfit', value: data.todayProfit },
          { key: 'pendingOrders', value: data.pendingOrdersCount },
          { key: 'completedOrders', value: data.completedOrdersCount }
        ];
        console.log('Stats updated:', stats.value);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        if (error.response && error.response.status === 401) {
          ElMessage.error(t('error.sessionExpired'));
          userStore.logout();
          router.push('/login');
        } else {
          ElMessage.error(t('error.fetchDataFailed'));
        }
      }
    };

    onMounted(() => {
      console.log('Dashboard component mounted');
      fetchDashboardData();
    });

    return {
      t,
      stats,
      formatStatValue
    };
  }
};
</script>

<style scoped>
.dashboard-container {
  padding: 20px;
}

.dashboard-content {
  flex-grow: 1;
  padding: 20px;
}

.box-card {
  margin-bottom: 20px;
}

.card-content {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
</style>