export const validateWalletAddress = (walletAddress, chain) => {
    if (!walletAddress) {
      return { valid: false, error: 'walletAddressError' }
    }
  
    // 验证 Tron 地址
    if (chain === 'tron') {
      const tronRegex = /^T[a-zA-Z0-9]{33}$/
      if (!tronRegex.test(walletAddress)) {
        return { valid: false, error: 'invalidTronAddress' }
      }
    }
  
    // 验证 Ethereum/Binance/Polygon 地址

    if (chain === 'eth' || chain === 'pol' || chain === 'bsc') {
      const ethPolBscRegex = /^0x[a-fA-F0-9]{40}$/
      if (!ethPolBscRegex.test(walletAddress)) {
        return { valid: false, error: 'invalidEthPolBscAddress' }
      }
    }
  
    return { valid: true }
  }
  