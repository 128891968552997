export default {
    message: {
        language: '语言',
        title: 'Rice 加密货币交易所',
        type: '交易类型',
        buy: '买入',
        sell: '卖出',
        amount: '港币金额',
        submit: '提交',
        amountError: '金额必须是100的倍数且至少3000港币',
        exchangeRateError: '获取汇率失败，请稍后再试',
        walletAddress: '钱包地址',
        enterWalletAddress: '输入您的钱包地址',
        chain: '链名称',
        exchangeRate: '汇率',
        amountHint: '金额必须是100的倍数，最低为1000港币',
        usdtAmount: 'USDT金额',
        orderNumber: '订单号',
        paymentAddress: '付款地址',
        copyAddress: '复制地址',
        copyFailed: '复制失败',
        addressCopied: '地址复制成功',
        reset: '重新下单',
        returnToHome: '返回首页',
        walletAddressError: '钱包地址是必需的',
        invalidTronAddress: '无效的Tron钱包地址',
        invalidBscAddress: '无效的Binance Smart Chain (BSC)钱包地址',
        orderSubmitted: '订单提交成功！',
        submitFailed: '订单提交失败。',
        adjustAmount: '调整金额',
        enterUSDT: '输入USDT金额',
        viewReceipt: '查看收据',
        pendingOrders: "待处理订单",
        noPendingOrders: "没有待处理订单",
        statusPending: "待处理",
        statusCompleted: "已完成",
        statusClosed: "已关闭",
        edit: "编辑",
        markCompleted: "标记为已完成",
        closeOrder: "关闭订单",
        resetOrder: "重置订单",
        uploadImage: "拍照",
        receiptTitle: 'Rice 加密货币找换所',
        orderTime: '订单创建日期',
        printTime: '收据打印时间',
        receiptDeclaration: '收据声明',
        receiptDeclarationContent: '我已核实上述交易详情和付款信息，并确认其准确性。一旦Rice商店将USDT转移到提供的地址，任何后续的USDT变动将完全由您负责。请确保您的钱包地址安全，以避免任何潜在的资金损失。此外，请确认您完全理解并承认此购买是由您本人进行的，并且您对这一行为承担全部责任。',
        printReceipt: '打印收据',
        orderType: "交易类型",
        buyOrder: "买入",
        sellOrder: "卖出",
        storeAddress: "商店地址",
        storeAddressContent: "香港九龙旺角弥敦道608号总统商业中心1楼150A铺",
        sellReceiptDeclarationContent: '我已核实上述交易详情和收款信息，并确认其准确性。我已收到应付现金，并确认USDT与现金的兑换已完全结清。请确认此交易仅由您本人在店内进行。请注意，在现场确认此交易后，Rice不对您离开场所后发生的任何问题负责。',
        startScanningError: '启动扫描时发生错误',
        deviceNotFound: '未找到请求的设备',
        cameraNotSupported: '您的设备不支持相机功能',
        scanningError: '扫描过程中发生错误',
        startScanning: '开始扫描',
        stopScanning: '停止扫描',
        dashboard: '仪表盘',
        todayTransactions: "今日交易",
        todayProfit: "今日利润",
        completedOrders: '已完成订单',
        viewPendingOrders: '查看待处理订单',
        status: '状态',
        selectStatus: '选择状态',
        startTime: '开始时间',
        endTime: '结束时间',
        startDate: '开始日期',
        endDate: '结束日期',
        search: '搜索',
        settings: '设置',
        buyPrice: '买入价',
        sellPrice: '卖出价',
        setQuotes: '设置报价',
        save: '保存',
        memberLogin: '会员登录',
        email: '电子邮件',
        password: '密码',
        login: '登录',
        memberPage: '会员页面',
        welcome: '欢迎',
        sendCode: '发送验证码',
        verificationCode: '验证码',
        buyUSDT: '购买USDT',
        hkdToUSDT: '港币 兑换 USDT',
        orderManagement: '订单管理',
        saveSuccess: '保存成功',
        noPermission: '您没有权限执行此操作',
        operationFailed: '操作失败，请稍后再试',
        logout: '登出',
        loading: '加载中...',
        receiveAddress: '收款地址',
        createdAt: '创建日期',
        fee: '手续费',
        transactionHash: '交易哈希',
        remark: '备注',
        sendAddress: '发送地址',
        all: '全部',
        amountInput: '请输入USDT金额或港币金额',
        sellUSDT: '卖出USDT',
        USDTToHKD: 'USDT 兑换 港币',
    }
}