<template>
  <div id="receipt-buy">
    <!-- 收据头部图片 -->
    <img src="@/assets/receipt_head.svg" alt="Receipt Header" class="receipt-header">

    <!-- 等宽的细线条 -->
    <div class="divider"></div>

    <!-- 第一行: 订单号和日期 -->
    <div class="title-row">
      <div class="column">
        <strong>訂單號 / Order Number:</strong>
      </div>
      <div class="column">
        <strong>日期 / Date:</strong>
      </div>
    </div>

    <!-- 第二行: 显示订单号和预下单时间 -->
    <div class="title-row">
      <div class="column">{{ orderNumber }}</div>
      <div class="column">{{ formattedOrderDate }}</div>
    </div>

    <!-- 交易信息部分使用 div 实现表格布局 -->
    <h4 style="text-align: left;margin-bottom: 10px;">交易信息 / Transaction Information</h4>

    <div class="transaction-info">
      <div class="row" style="border-top: 1px solid #ccc;">
        <div class="column-label">支付港幣 / Pay HKD</div>
        <div class="column-value">{{ formatNumberWithCommasNoDecimals(amount) }} HKD</div>
      </div>
      <div class="row">
        <div class="column-label">匯率 / Exchange Rate</div>
        <div class="column-value">{{ exchangeRate }} HKD/USDT</div>
      </div>
      <div class="row">
        <div class="column-label">兌換USDT數量 / USDT Amount</div>
        <div class="column-value">{{ formatNumberWithCommas(usdtAmount) }} USDT</div>
      </div>
      <div class="row">
        <div class="column-label">接收地址 / Receiving Address</div>
        <div class="column-value">{{ chainType }}: {{ walletAddress }}</div>
      </div>
    </div>

    <!-- 裁剪线 -->
    <div style="border-top: 1px dashed #000; margin: 20px 0;"></div>

    <!-- 存根部分 -->
    <h4 style="text-align: left; margin-bottom: 10px;">店铺存根 / Store Copy</h4>
    <div class="transaction-summary">
      <p style="margin: 0;">
        訂單號 {{ orderNumber }}，於 {{ formattedOrderDate }} 交易。支付港幣 {{ formatNumberWithCommasNoDecimals(amount) }} HKD，兌換
        USDT 數量為 {{ formatNumberWithCommas(usdtAmount) }}，匯率為 {{ exchangeRate }}。
      </p>
      <p style="margin: 0;">
        Order Number {{ orderNumber }}. Transaction completed on {{ formattedOrderDate }}. Paid {{
          formatNumberWithCommasNoDecimals(amount) }} HKD in exchange for {{ formatNumberWithCommas(usdtAmount) }} USDT.
        The exchange rate was {{ exchangeRate }}.
      </p>
    </div>


    <div class="transaction-info">

      <div class="row" style="border-top: 1px solid #ccc;">
        <div class="column-label">接收地址 / Receiving Address</div>
        <div class="column-value">{{ chainType }}: {{ walletAddress }}</div>
      </div>
      <div class="row">
        <div class="column-label">地址二維碼 / Address QR Code</div>
        <div class="column-value" style="text-align: center;">
          <qrcode-vue v-if="walletAddress" :value="walletAddress" :size="150"></qrcode-vue>
        </div>
      </div>
    </div>

    


    <div class="signature-info">
      <div class="row four-columns" style="border-top: 1px solid #ccc;">
        <div class="column-signature">客戶簽名 <br />Customer Signature</div>
        <div class="column-empty"></div>
        <div class="column-signature">電話<br />Telephone</div>
        <div class="column-empty"></div>
      </div>
      <div class="row full-width">

        <div class="column-text">
          <h3 style="margin: 0 0 10px 0;">確認聲明 / Confirmation Statement:</h3>
          我已核實上述交易詳情及收款信息，並確認其準確無誤。在Rice店方將USDT轉帳至以上地址後，該地址上的USDT再發生的任何變化，均由您自行負責。請注意確保您的錢包地址安全，以避免任何潛在的資金損失。此外，請確認您完全知曉並理解此次購買行為是由您本人所為，並且您對該行為負全部責任。<br />
          I have verified the above transaction details and receipt information and confirm that they are accurate.
          After Rice transfers the USDT to the above address, any further changes to the USDT at that address are your
          responsibility. Please ensure the security of your wallet address to avoid any potential loss of funds.
          Additionally, please confirm that you fully understand and acknowledge that this purchase is made by you
          personally, and you take full responsibility for your actions.
        </div>
      </div>
    </div>


    <!-- 打印按钮 -->
    <el-button class="no-print" @click="printReceipt">打印收据</el-button>
    <el-button class="no-print" type="primary" @click="goToHome">{{ t('message.returnToHome') }}</el-button>
  </div>
</template>

<script>
import axios from 'axios'
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import QrcodeVue from 'vue-qrcode'
import { formatNumberWithCommas, formatNumberWithCommasNoDecimals } from '@/utils/formatNumber'

export default {
  components: {
    QrcodeVue
  },
  props: ['orderNumber'],
  setup(props) {
    const { t } = useI18n()

    const amount = ref('')
    const usdtAmount = ref('')
    const walletAddress = ref('')
    const orderDate = ref('')
    const chainType = ref('') // 链类型
    const exchangeRate = ref('') // 汇率

    // 格式化日期为 "YYYY-MM-DD HH:mm:ss"
    const formattedOrderDate = ref('')

    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(`https://order.pcdao.org/api/preorders?order_number=${props.orderNumber}`)
        const data = response.data
        amount.value = data.amount
        usdtAmount.value = data.usdt_amount
        walletAddress.value = data.receive_address
        orderDate.value = data.created_at
        chainType.value = data.chain_type
        exchangeRate.value = data.exchange_rate
        formattedOrderDate.value = new Date(orderDate.value).toLocaleString('zh-CN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        })
      } catch (error) {
        console.error('获取订单详情失败:', error)
      }
    }

    onMounted(fetchOrderDetails)

    const printReceipt = () => {
      window.print()
    }

    const goToHome = () => {
      window.location.href = '/'
    }

    return {
      amount,
      usdtAmount,
      walletAddress,
      chainType,
      exchangeRate,
      printReceipt,
      goToHome,
      t,
      formattedOrderDate,
      formatNumberWithCommas,
      formatNumberWithCommasNoDecimals
    }
  }
}
</script>

<style>
#receipt-buy {
  width: 210mm;
  padding: 20mm;
  margin: 0 auto;
  text-align: center;
}

.receipt-header {
  width: 100%;
  height: auto;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #000;
  margin: 10px 0;
}

.transaction-info {
  width: 100%;
  border-collapse: collapse;
}

.title-row {
  display: flex;
  justify-content: space-between;
}

.row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  /* 为每一行添加底部边框 */
  border-left: 1px solid #ccc;
  /* 左侧的线条 */
  border-right: 1px solid #ccc;
  /* 右侧的线条 */
  padding: 0px 0;
}

.column-label {
  width: 35%;
  font-weight: bold;
  text-align: left;
  padding: 10px 0 10px 10px;
}

.column-value {
  width: 65%;
  text-align: right;
  padding: 10px 10px 10px 0;
  border-left: 1px solid #ccc;
  /* 中间的竖线 */
}

.qrcode {
  text-align: center;
}

.usdt-amount {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}

@media print {
  body * {
    visibility: hidden;
  }

  #receipt-buy,
  #receipt-buy * {
    visibility: visible;
  }

  #receipt-buy {
    position: absolute;
    left: 0;
    top: 0;
  }

/* 隐藏带有 no-print 类的所有元素 */
  .no-print {
    display: none !important;  /* 使用 !important 确保覆盖其他样式 */
  }

  /* 还可以隐藏特定的 UI 框架中的按钮样式 */
  button, .el-button {
    display: none !important;
  }

}


.signature-info {
  width: 100%;
  margin-top: 20px;
}

.four-columns {
  display: flex;
  justify-content: space-between;
}

.column-signature {
  width: 25%;
  /* 4列均分 */
  text-align: left;
  font-weight: bold;
  padding: 10px 0 10px 10px;
}

.column-empty {
  width: 25%;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.full-width {
  display: flex;
  justify-content: center;
  /* 中心对齐 */
  padding: 10px;
}

.column-text {
  width: 100%;
  text-align: left;
  /* 文本居中 */
  font-size: 14px;
}

.transaction-summary {
  font-size: 14px;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 10px;
}
</style>
