import axios from 'axios';
import { useUserStore } from '@/stores/user';

import { API_BASE_URL } from '../apiConfig';

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const userStore = useUserStore();
    if (userStore.jwtToken) {
      config.headers['Authorization'] = `Bearer ${userStore.jwtToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// ... 响应拦截器保持不变

export default api;