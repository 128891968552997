<template>
  <div class="member-login">
    <el-row>
      <el-col :span="8" :offset="8">
        <h1>{{ t("message.memberLogin") }}</h1>
        <el-form :model="form" label-width="120px">
          <el-form-item :label="t('message.email')">
            <el-input v-model="form.email" type="email" placeholder="Enter your email"></el-input>
          </el-form-item>
          <el-form-item :label="t('message.password')">
            <el-input v-model="form.password" type="password" placeholder="Enter your password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">{{ t("message.login") }}</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import axios from 'axios'; // 引入 axios
import { API_BASE_URL } from '@/apiConfig'; // 引入 API_BASE_URL
import { useUserStore } from '@/stores/user';

export default {
  name: 'MemberLogin',
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const userStore = useUserStore();

    const form = ref({
      email: '',
      password: ''
    });

    const submitForm = async () => {
      try {
        const response = await axios.post(`${API_BASE_URL}/member/login`, form.value);
        if (response.data.success) {
          userStore.setDidToken(response.data.token);
          userStore.setUserInfo(response.data.user);
          router.push('/member');
        } else {
          console.error('Login failed:', response.data.message);
        }
      } catch (error) {
        console.error('Login failed:', error);
      }
    };

    return {
      t,
      form,
      submitForm
    };
  }
};
</script>

<style scoped>
.member-login {
  padding: 20px;
}
</style>