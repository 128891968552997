<template>
  <div class="member-page">
    <el-row>
      <el-col :span="4">
        <SidebarMenu />
      </el-col>
      <el-col :span="18" class="content-col">
        <h1>{{ t("message.memberPage") }}</h1>
        <p>{{ t("message.welcome") }}, {{ userInfo.email }}</p>
        <!-- 顯示更多會員信息 -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import SidebarMenu from '@/components/SidebarMenu.vue'; // 引入 SidebarMenu 組件

export default {
  name: 'MemberPage',
  components: {
    SidebarMenu // 註冊 SidebarMenu 組件
  },
  setup() {
    const { t } = useI18n();
    const userStore = useUserStore();

    const userInfo = computed(() => userStore.getUserInfo);

    return {
      t,
      userInfo
    };
  }
};
</script>

<style scoped>
.member-page {
  padding: 20px;
}
.content-col {
  margin-left: 20px;
  margin-right: 20px;
}
</style>