<template>
  <div class="buy-page" v-if="!isSubmitted" style="margin:20px;">

    <div style="text-align: center; margin-bottom: 20px;">
      <h1 style="font-size: 28px; font-weight: bold; color: #333;">{{ $t('message.buyUSDT') }}</h1>
      <p style="font-size: 18px; color: #666;">{{ $t('message.hkdToUSDT') }}</p>
    </div>

    <el-form :model="form" label-width="100px">
      <el-card class="box-card" style="margin-bottom: 20px;">
        <template #header>
          <div class="card-header" style="text-align: left;">
            <span>{{ $t('message.amountInput') }}</span>
          </div>
        </template>
        <!-- 增加USDT输入框 -->
        <el-form-item :label="$t('message.usdtAmount')">
          <el-input v-model.number="form.usdtAmount" @input="calculateHKD" :placeholder="$t('message.enterUSDT')">
            <template #append>
              <div style="width: 50px; text-align: center;">USDT</div>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('message.amount')">
          <el-input v-model.number="form.amount" :placeholder="$t('message.amount')">
            <template #append>
              <div style="width: 50px; text-align: center;">HKD</div>
            </template>
          </el-input>
          
          <div style="display: flex; flex-direction: column;">
            <div v-if="form.chain != 'binance' && (form.amount % 100 !== 0 || form.amount < minAmount)"
              class="error-message">
              {{ t("message.amountHint") }}
            </div>

            <!-- 增加取整功能按钮 -->
            <el-button type="success" @click="adjustHKD" :style="{
                display: 'block', 
                marginTop: (form.amount % 100 !== 0 || form.amount < minAmount) ? '0' : '10px'
              }">
              {{ t("message.adjustAmount") }}
            </el-button>
          </div>

        </el-form-item>
      </el-card>

      <el-form-item :label="t('message.amount')">
        <div class="usdt-amount">{{ formatNumberWithCommasNoDecimals(form.amount) }} <img src="@/assets/hkd.png" alt="HKD" style="width: 30px; height: 30px; margin-left: 5px;" /></div>
      </el-form-item>


      <el-form-item :label="$t('message.exchangeRate')">
        <div style="display: flex; align-items: center;">
          <el-button v-if="allowAdjust" @click="decreaseExchangeRate">-</el-button>
          <el-input v-model="exchangeRate" disabled style="width: 100px; text-align: center;"></el-input>
          <el-button v-if="allowAdjust" @click="increaseExchangeRate">+</el-button>
        </div>
      </el-form-item>

      <el-form-item :label="t('message.usdtAmount')">
        <div class="usdt-amount">{{ formatNumberWithCommas(usdtAmount) }} <img src="@/assets/usdt_logo.svg" alt="USDT" style="width: 30px; height: 30px; margin-left: 5px;" /></div>
      </el-form-item>

      <el-form-item :label="$t('message.chain')">
        <el-select v-model="form.chain" placeholder="Select Chain" @change="handleChainChange">
          <el-option label="Tron / TRC20" value="tron"></el-option>
          <el-option label="Binance Smart Chain" value="bsc"></el-option>
          <el-option label="Ethereum" value="eth"></el-option>
          <el-option label="Polygon" value="pol"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('message.walletAddress')">
        <!-- 手动输入钱包地址 -->
        <el-input v-model="form.walletAddress" :placeholder="$t('message.enterWalletAddress')"></el-input>

        <!-- 隐藏的文件输入 -->
    <input type="file" ref="fileInput" @change="onFileChange" accept="image/*" style="display: none;" />

        <!-- 或者使用 qrcode-capture 用于文件上传 -->
        <qrcode-capture @detect="onDecode" ref="qrcodeCapture" style="display: none;">
        </qrcode-capture>

        <!-- 自定义按钮 -->
        <el-button type="success" @click="triggerFileInput" style="margin-top: 10px;">{{
          t("message.uploadImage")
          }}</el-button>

        <!-- 显示用户上传的二维码图片 -->
        <div v-if="qrCodeImage">
          <img :src="qrCodeImage" alt="Uploaded QR Code" style="max-width: 100%" />
        </div>
      </el-form-item>

    </el-form>

    <el-button type="primary" @click="submitForm" style="width: 100%; margin-left: 0;">{{
          $t("message.submit")
          }}</el-button>

  </div>

  <!-- 提交后的显示部分 -->
  <div v-else class="submission-result">
    <div class="receipt">
      <h2>{{ t("message.receiptTitle") }}</h2>
      <p>
        <strong>{{ t("message.orderNumber") }}:</strong> {{ orderNumber }}
      </p>
      <p>
        <strong>{{ t("message.orderTime") }}:</strong>
        {{ formatDateToCustom(convertUTCToLocalTime(orderCreated_at)) }}
      </p>

      <p style="font-size: 24px">
        <strong>{{ t("message.amount") }}:</strong>
        {{ formatNumberWithCommasNoDecimals(form.amount) }} HKD
      </p>
      <p>
        <strong>{{ t("message.usdtAmount") }}:</strong>
        {{ formatNumberWithCommas(usdtAmount) }} USDT
      </p>
      <p>
        <strong>{{ t("message.exchangeRate") }} {{ exchangeRate }} HKD/USDT</strong>
      </p>
      <p>
        <strong>{{ t("message.walletAddress") }}:</strong>
        {{ form.walletAddress }}
      </p>

      <!-- QR Code for Wallet Address -->
      <qrcode-vue :value="form.walletAddress" :size="150"></qrcode-vue>

      <p>
        <strong>{{ t("message.receiptDeclaration") }}:</strong>
      </p>
      <p>{{ t("message.receiptDeclarationContent") }}</p>

      <p>
        <strong>{{ t("message.printTime") }}:</strong>
        {{ formatDateToCustom(new Date()) }}
      </p>

      <!-- Print button -->
      <el-button type="primary" @click="printReceipt">{{
        t("message.printReceipt")
        }}</el-button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, nextTick } from "vue";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import {
  formatNumberWithCommas,
  formatNumberWithCommasNoDecimals,
} from "@/utils/formatNumber";
import { validateWalletAddress } from "@/utils/validateWalletAddress";
import { API_BASE_URL, PRICE_API_URL } from "@/apiConfig";
import generateOrderId from "@/utils/generateOrderId";
import QrcodeVue from "vue-qrcode";
import { useRouter } from "vue-router";


import { QrcodeCapture } from 'vue-qrcode-reader'

export default {
  components: {
    QrcodeVue, // 引入二维码组件
    // QrcodeStream,
    QrcodeCapture
  },
  props: {
    allowAdjust: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const router = useRouter(); // 初始化 router
    const { t } = useI18n();
    const qrCodeImage = ref(""); // 保存上传的图片 URL

    const orderCreated_at = ref();
    const minAmount = ref(process.env.VUE_APP_MIN_AMOUNT || 1000);
    const form = ref({
      amount: "",
      walletAddress: "",
      chain: "tron",
    });
    const exchangeRate = ref(null);
    const usdtAmount = ref("");
    const isSubmitted = ref(false);
    const orderNumber = ref("");
    const isScanning = ref(false);
    const video = ref(null);


    const qrcodeCapture = ref(null)

    const calculateUSDT = () => {
      if (form.value.amount && exchangeRate.value) {
        usdtAmount.value = (form.value.amount / exchangeRate.value).toFixed(4);
      } else {
        usdtAmount.value = "";
      }
    };

    watch([() => form.value.amount, exchangeRate], calculateUSDT);

    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get(PRICE_API_URL);
        const data = response.data;
        exchangeRate.value = data.buyPrice;
      } catch (error) {
        console.error("获取汇率失败:", error);
        ElMessage.error(t("message.exchangeRateError"));
      }
    };

    const handleChainChange = () => {
      form.value.walletAddress = "";
    };

    const validateForm = () => {
      console.log('form:', form.value);
      if (form.value.chain === 'binance') {
        console.log('binance-不验证');
        return true;
      }
      // 金额验证，必须是100的倍数
      if (
        form.value.amount % 100 !== 0 ||
        form.value.amount < minAmount.value
      ) {
        ElMessage.error(t("message.amountError"));
        return false;
      }

      console.log('form.value.walletAddress:', form.value.walletAddress);
      console.log('form.value.chain:', form.value.chain);
      // 验证钱包地址格式是否正确
      const addressValidation = validateWalletAddress(
        form.value.walletAddress,
        form.value.chain
      );
      if (!addressValidation.valid) {
        ElMessage.error(t(`message.${addressValidation.error}`));
        return false;
      }

      return true;
    };





    // 增加汇率调整功能
    const increaseExchangeRate = () => {
      exchangeRate.value = (parseFloat(exchangeRate.value) + 0.001).toFixed(4); // 每次增加 0.001
      calculateUSDT();
    };

    const decreaseExchangeRate = () => {
      exchangeRate.value = (parseFloat(exchangeRate.value) - 0.001).toFixed(4); // 每次减少 0.001
      calculateUSDT();
    };

    const submitForm = async () => {
      if (!validateForm()) return;

      orderNumber.value = generateOrderId();

      const formData = new FormData();
      formData.append("order_number", orderNumber.value);
      formData.append("amount", form.value.amount);
      formData.append("usdt_amount", usdtAmount.value);
      formData.append("exchange_rate", exchangeRate.value);
      formData.append("receive_address", form.value.walletAddress);
      formData.append("chain_type", form.value.chain);
      formData.append("order_type", "buy");

      // 附加文件
      const fileInput = document.querySelector('input[type="file"]');
      const file = fileInput.files[0];
      if (file) {
        formData.append("qr_code_image", file);
      }

      try {
        const response = await axios.post(
          `${API_BASE_URL}/preorder`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // // 提交成功后，接收后端返回的订单数据
        const orderData = response.data.order;

        orderNumber.value = orderData.order_number;
        form.value.amount = orderData.amount;
        usdtAmount.value = orderData.usdt_amount;
        exchangeRate.value = orderData.exchange_rate;
        form.value.walletAddress = orderData.receive_address;
        form.value.chain = orderData.chain_type;
        orderCreated_at.value = orderData.created_at;

        // 显示成功消息
        ElMessage.success(t("message.orderSubmitted"));
        isSubmitted.value = true;
      } catch (error) {
        console.error("订单提交失败:", error);
        ElMessage.error(t("message.submitFailed"));
      }
    };

    const goToHome = () => {
      // 重置表单
      form.value = {
        amount: "",
        walletAddress: "",
        chain: "tron",
      };
      usdtAmount.value = "";
      isSubmitted.value = false;
    };

    // 计算 USDT 对应的港币金额
    const calculateHKD = () => {
      if (exchangeRate.value && form.value.usdtAmount > 0) {
        form.value.amount = (
          form.value.usdtAmount * exchangeRate.value
        ).toFixed(2);
      }
    };

    // 取整港币金额为 100 的倍数
    const adjustHKD = () => {
      if (form.value.amount % 100 !== 0) {
        // 将港币金额调整为最近的 100 的倍数
        form.value.amount = Math.round(form.value.amount / 100) * 100;
        // 根据调整后的港币金额重新计算USDT
        form.value.usdtAmount = (
          form.value.amount / exchangeRate.value
        ).toFixed(4);
      }
    };

    const viewReceipt = () => {
      router.push({
        name: "ReceiptBuy",
        params: {
          orderNumber: orderNumber.value,
        },
      });
    };

    const fileInput = ref(null);

    // 触发 file input 的点击事件
    const triggerFileInput = () => {
      fileInput.value.click();
    };

    const onFileChange = async (event) => {
      const file = event.target.files[0]
      if (file) {
        // 显示选择的图片
        const reader = new FileReader()
        reader.onload = (e) => {
          qrCodeImage.value = e.target.result
        }
        reader.readAsDataURL(file)

        // 将文件传递给 qrcode-capture 组件
        await nextTick()
        if (qrcodeCapture.value && qrcodeCapture.value.$el) {
          const dataTransfer = new DataTransfer()
          dataTransfer.items.add(file)
          qrcodeCapture.value.$el.files = dataTransfer.files
          qrcodeCapture.value.$el.dispatchEvent(new Event('change', { bubbles: true }))
        }
      }
    }


    const triggerQrcodeCapture = () => {
      if (qrcodeCapture.value) {
        const inputElement = qrcodeCapture.value.$el
        if (inputElement && inputElement.tagName === 'INPUT') {
          inputElement.click()
        }
      }
    }







    const printReceipt = () => {
      router.push({ name: "PrintReceipt", params: { orderNumber: orderNumber.value } });
    };

    function formatDateToCustom(dateString) {
      // 将原始日期字符串转换为 Date 对象
      const date = new Date(dateString);

      // 获取年份、月份、日期、小时、分钟和秒
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      // 拼接成所需的格式 "喻喆 YYYY-MM-DD HH:mm:ss"
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    function convertUTCToLocalTime(utcTimeString) {
      // 将 UTC 时间字符串转换为 Date 对象
      const utcDate = new Date(utcTimeString);

      // 使用 toLocaleString 将时间转换为本地时区，并自定义格式
      return utcDate.toLocaleString("zh-CN", {
        timeZone: "Asia/Hong_Kong", // 设定特定的时区
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    }


    const onDecode = (content) => {
      const decodedContent = content[0].rawValue;
      console.log('解碼的二維碼內容:', decodedContent);
      
      // 檢查是否符合特定格式
      const match = decodedContent.match(/^(ethereum:)?(0x[0-9a-fA-F]{40})/);
      console.log('match:', match);
      if (match) {
        // 提取地址
        form.value.walletAddress = match[2];
        // 設置對應的鏈為 eth
        form.value.chain = 'eth';
        console.log('檢測到以太坊地址:', form.value.walletAddress);
        console.log('設置鏈為:', form.value.chain);
      } else {
        // 如果不符合特定格式，則使用原始內容
        form.value.walletAddress = decodedContent;
        console.log('使用原始地址:', form.value.walletAddress);
      }
    };

    onMounted(async () => {
      console.log('props:', props.allowAdjust);
      await fetchExchangeRate();
    });

    return {
      form,
      exchangeRate,
      usdtAmount,
      isSubmitted,
      orderNumber,
      submitForm,
      handleChainChange,
      t,
      formatNumberWithCommas,
      goToHome,
      formatNumberWithCommasNoDecimals,
      adjustHKD,
      calculateHKD,
      viewReceipt,
      minAmount,
      qrCodeImage,
      triggerQrcodeCapture,
      isScanning,
      video,
      printReceipt,
      orderCreated_at,
      formatDateToCustom,
      convertUTCToLocalTime,
      increaseExchangeRate,
      decreaseExchangeRate,
      onDecode,
      qrcodeCapture,
      triggerFileInput,
      onFileChange,
      fileInput
    };
  },
};
</script>

<style>
.error-message {
  color: red;
  margin-top: 5px;
  font-size: 12px;
}

.usdt-amount {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}

.submission-result {
  margin-top: 20px;
}

.submission-result p {
  font-size: 16px;
}

.buy-page {
  margin: 20px;
}
.buy-page .el-button {
  margin-left: 0px;
}

@media print {
  body {
    width: 57mm;
    margin: 0;
    padding: 0;
  }

  .receipt {
    width: 100%;
    font-size: 12px;
    line-height: 1.2;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .no-print,
  button,
  .el-button {
    display: none !important;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}


</style>
