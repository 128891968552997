<template>
  <div class="pending-orders">
    <el-row>
      <el-col :span="4">
        <SidebarMenu />
      </el-col>
      <el-col :span="18" class="content-col">
        <h1>{{ t("message.pendingOrders") }}</h1>

        <!-- 查询条件 -->
        <el-form :inline="true" class="rice-form-inline">
          <!-- 訂單號輸入框 -->
          <el-form-item :label="t('message.orderNumber')">
            <el-input v-model="queryParams.order_number" placeholder="請輸入訂單號"></el-input>
          </el-form-item>
          <!-- 接收地址输入框 -->
          <el-form-item :label="t('message.receiveAddress')">
            <el-input v-model="queryParams.receive_address" placeholder="請輸入接收地址"></el-input>
          </el-form-item>

          <!-- 發送地址输入框 -->
          <el-form-item :label="t('message.sendAddress')">
            <el-input v-model="queryParams.send_address" placeholder="請輸入發送地址"></el-input>
          </el-form-item>
          <!-- 状态选择器 -->
          <el-form-item :label="t('message.status')">
            <el-select v-model="queryParams.status" :placeholder="t('message.selectStatus')" @change="handleStatusChange" value="">
              <el-option :label="t('message.all')" value="all"></el-option>
              <el-option :label="t('message.statusPending')" value="pending"></el-option>
              <el-option :label="t('message.statusCompleted')" value="completed"></el-option>
              <el-option :label="t('message.statusClosed')" value="closed"></el-option>
            </el-select>
          </el-form-item>

          <!-- 开始时间选择器 -->
          <el-form-item :label="t('message.startTime')">
            <el-date-picker v-model="queryParams.start_time" type="date" :placeholder="t('message.startDate')"
              :disabled-date="disableFutureDates" :shortcuts="shortcuts" />
          </el-form-item>

          <!-- 结束时间选择器 -->
          <el-form-item :label="t('message.endTime')">
            <el-date-picker v-model="queryParams.end_time" type="date" :placeholder="t('message.endDate')" :shortcuts="shortcuts"
              :disabled-date="disableFutureDates"></el-date-picker>
          </el-form-item>

          <!-- 搜索按钮 -->
          <el-form-item>
            <el-button type="primary" @click="fetchPendingOrders">{{ t("message.search") }}</el-button>
          </el-form-item>
        </el-form>

        
        <!-- 订单列表 -->
        <el-table :data="orders" v-if="orders.length > 0" border>
          <el-table-column label="Order Details">
            <template #default="scope">
              <div class="order-details">
                <!-- 第一行 -->
                <div class="order-row">
                  <div class="order-col">
                    <strong>{{ t("message.orderNumber") }}:</strong> {{ scope.row.order_number }}
                  </div>
                  <div class="order-col">
                    <strong>{{ t("message.status") }}:</strong> 
                    {{
                      scope.row.status === "pending"
                        ? t("message.statusPending")
                        : scope.row.status === "completed"
                          ? t("message.statusCompleted")
                          : t("message.statusClosed")
                    }}
                  </div>
                </div>
                <!-- 第二行 -->
                <div class="order-row">
                  <div class="order-col">
                    <qrcode-vue :value="scope.row.receive_address" :size="100"></qrcode-vue>
                  </div>
                  <div class="order-col">
                    
                    <div><strong>{{ t("message.orderType") }}:</strong> {{ scope.row.order_type === 'buy' ? t("message.buyOrder") : t("message.sellOrder") }}</div>
                    <div v-if="scope.row.order_type === 'sell'">
                      <div>{{ t("message.usdtAmount") }}: {{ formatNumberWithCommas(scope.row.usdt_amount) }}</div>
                      <div>{{ t("message.exchangeRate") }}: {{ scope.row.exchange_rate }}</div>
                      <div>{{ t("message.amount") }} (HKD): {{ formatNumberWithCommasNoDecimals(scope.row.amount) }}</div>
                    </div>
                    <div v-else>
                      <div>{{ t("message.amount") }} (HKD): {{ formatNumberWithCommasNoDecimals(scope.row.amount) }}</div>
                      <div>{{ t("message.exchangeRate") }}: {{ scope.row.exchange_rate }}</div>
                      <div>{{ t("message.usdtAmount") }}: {{ formatNumberWithCommas(scope.row.usdt_amount) }}</div>
                    </div>
                    <div>{{ t("message.fee") }}: {{ formatNumberWithCommas(scope.row.fee_usdt) }}</div>
                    <div>{{ t("message.transactionHash") }}: {{ scope.row.transaction_hash }}</div>
                    <div>{{ t("message.remark") }}: {{ scope.row.remark }}</div>
                  </div>
                </div>
                <!-- 第三行 -->
                <div class="order-row">
                  <div class="order-col">
                    <strong>{{ t("message.receiveAddress") }}:</strong> {{ scope.row.receive_address }}
                  </div>
                  <div class="order-col">
                    <strong>{{ t("message.createdAt") }}:</strong> {{ formatDateToView(scope.row.created_at) }}
                  </div>
                </div>
                <!-- 第四行 -->
                <div class="order-row">
                  <div class="order-col">
                    <el-button v-if="scope.row.status === 'pending'" type="success"
                      @click="markAsCompleted(scope.row.order_number)">
                      {{ t("message.markCompleted") }}
                    </el-button>
                    <el-button v-if="scope.row.status === 'pending'" type="warning" @click="closeOrder(scope.row.order_number)">
                      {{ t("message.closeOrder") }}
                    </el-button>
                    <el-button v-if="scope.row.status === 'pending'" type="primary" @click="handleEdit(scope.row.order_number)">
                      {{ t("message.edit") }}
                    </el-button>
                    <el-button v-if="scope.row.status === 'closed'" type="primary" @click="resetOrder(scope.row.order_number)">
                      {{ t("message.resetOrder") }}
                    </el-button>
                    <el-button v-if="scope.row.status === 'pending'" type="primary"
                      @click="printReceipt(scope.row.order_number)">{{
                        t("message.printReceipt")
                      }}</el-button>
                    <el-button v-if="scope.row.status === 'pending'" type="primary"
                      @click="printMoneyReceipt(scope.row.order_number, scope.row.order_type)">
                      {{ t("message.printReceipt") + 'A4' }}
                    </el-button>

                      
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页控件 -->
        <el-pagination v-if="total > 0" background :current-page="queryParams.page" :page-size="queryParams.limit"
          :total="total" @current-change="handlePageChange"></el-pagination>

        <div v-else>
          <p>{{ t("message.noPendingOrders") }}</p>
        </div>

        <!-- 编辑弹窗 -->
        <el-dialog v-model="isEditDialogVisible" title="Edit Order">
          <el-form :model="editForm">
            <el-form-item label="Order Number">
              <el-input v-model="editForm.order_number" disabled></el-input>
            </el-form-item>

            <!-- 金額 -->
            <el-form-item label="Amount (HKD)">
              <el-input v-model.number="editForm.amount" type="number" step="0.01" placeholder="Enter amount in HKD">
              </el-input>
            </el-form-item>

            <!-- USDT數量 -->
            <el-form-item label="USDT Amount">
              <el-input v-model.number="editForm.usdt_amount" type="number" step="0.000001" placeholder="Enter USDT amount">
              </el-input>
            </el-form-item>

            <!-- 匯率 -->
            <el-form-item label="Exchange Rate">
              <el-input v-model.number="editForm.exchange_rate" type="number" step="0.001"
                placeholder="Enter a decimal value">
              </el-input>
            </el-form-item>

            <!-- 訂單類型 -->
            <el-form-item label="Order Type">
              <el-select v-model="editForm.order_type">
                <el-option label="Buy" value="buy"></el-option>
                <el-option label="Sell" value="sell"></el-option>
              </el-select>
            </el-form-item>

            <!-- 鏈的名稱 -->
            <el-form-item label="Chain Type">
              <el-select v-model="editForm.chain_type">
                <el-option label="Tron / TRC20" value="tron"></el-option>
                <el-option label="Binance Smart Chain / BEP20" value="bsc"></el-option>
              </el-select>
            </el-form-item>

            <!-- 發送地址 -->
            <el-form-item label="Send Address">
              <el-input v-model="editForm.send_address"></el-input>
            </el-form-item>

            <!-- 接 -->
            <el-form-item label="Receive Address">
              <el-input v-model="editForm.receive_address"></el-input>
            </el-form-item>

            <!-- 地址類型 -->
            <el-form-item label="Address Type">
              <el-select v-model="editForm.address_type">
                <el-option label="Wallet" value="wallet"></el-option>
                <el-option label="Exchange" value="exchange"></el-option>
              </el-select>
            </el-form-item>

            <!-- 交易Hash -->
            <el-form-item label="Transaction Hash">
              <el-input v-model="editForm.transaction_hash"></el-input>
            </el-form-item>

            <el-form-item label="Fee">
              <el-input v-model="editForm.fee_usdt"></el-input>
            </el-form-item>

            <el-form-item label="Remark">
              <el-input v-model="editForm.remark"></el-input>
            </el-form-item>
          </el-form>

          <template v-slot:footer>
            <div class="dialog-footer">
              <el-button @click="isEditDialogVisible = false">Cancel</el-button>
              <el-button type="primary" @click="submitEdit">Save</el-button>
            </div>
          </template>
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import { ref, onMounted, reactive } from "vue";
import { useI18n } from "vue-i18n";
import {
  formatNumberWithCommas,
  formatNumberWithCommasNoDecimals,
} from "@/utils/formatNumber";
import QrcodeVue from "vue-qrcode";
import { ElMessageBox, ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { useUserStore } from '@/stores/user';
import SidebarMenu from '@/components/SidebarMenu.vue'; // 引入 SidebarMenu 组件
import api from '@/utils/api';

export default {
  components: {
    QrcodeVue,
    SidebarMenu // 注册 SidebarMenu 组件
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const { t } = useI18n();

    const orders = ref([]);
    const total = ref(0);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}T16:00:00Z`;
    }

    const queryParams = reactive({
      status: "all",
      start_time: formatDate(yesterday),
      end_time: formatDate(today),
      page: 1,
      limit: 30,
    });
    const isEditDialogVisible = ref(false);
    const editForm = ref({});

    const fetchPendingOrders = async () => {
      try {
        const params = {
          status: queryParams.status,
          start_time: queryParams.start_time,
          end_time: queryParams.end_time,
          page: queryParams.page,
          limit: queryParams.limit,
        };

        console.log('Fetching pending orders with params:', params);

        const response = await api.get('/pending', {
          params,
        });

        console.log('Response data:', response.data);
        if (response.data.orders) {
          orders.value = response.data.orders;
        } else {
          orders.value = [];
        }
        total.value = response.data.total || 0;
      } catch (error) {
        console.error("Error fetching pending orders:", error);
        if (error.response && error.response.status === 401) {
          console.log('Unauthorized access, redirecting to login');
          router.push('/login');
        } else if (error.response && error.response.status === 403) {
          console.log('Forbidden access, user is not admin');
          ElMessage.error('You do not have permission to view pending orders');
        }
        orders.value = [];
      }
    };

    const handlePageChange = (newPage) => {
      queryParams.page = newPage;
      fetchPendingOrders();
    };

    const handleEdit = (orderNumber) => {
      const order = orders.value.find(
        (order) => order.order_number === orderNumber
      );
      editForm.value = { ...order };
      isEditDialogVisible.value = true;
    };

    const submitEdit = async () => {
      try {
        await api.put('/preorder', editForm.value);

        isEditDialogVisible.value = false;
        fetchPendingOrders();
        ElMessage.success(t('message.editSuccess')); // 添加修改成功的提示
      } catch (error) {
        console.error("Error updating order:", error);
        if (error.response && error.response.status === 401) {
          console.log('Unauthorized access, redirecting to login');
          router.push('/login');
        } else if (error.response && error.response.status === 403) {
          console.log('Forbidden access, user is not admin');
          ElMessage.error(t('message.noPermission'));
        } else {
          ElMessage.error(t('message.editFailed')); // 添加修改失败的提示
        }
      }
    };

    const updateOrderStatus = async (orderNumber, status) => {
      try {
        await api.put('/status', {
          order_number: orderNumber,
          new_status: status
        });
        fetchPendingOrders();
      } catch (error) {
        console.error("Error updating order status:", error);
        if (error.response && error.response.status === 401) {
          router.push('/login');
        } else if (error.response && error.response.status === 403) {
          ElMessage.error('You do not have permission to perform this action');
        }
      }
    };

    const markAsCompleted = (orderNumber) => {
      ElMessageBox.confirm(
        "Are you sure you want to mark this order as completed?",
        "Confirm",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          updateOrderStatus(orderNumber, "completed");
        })
        .catch(() => {
          ElMessage.info("Action cancelled");
        });
    };

    const closeOrder = (orderNumber) => {
      ElMessageBox.confirm(
        "Are you sure you want to close this order?",
        "Confirm",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          updateOrderStatus(orderNumber, "closed");
        })
        .catch(() => {
          ElMessage.info("Action cancelled");
        });
    };

    const resetOrder = (orderNumber) => {
      ElMessageBox.confirm(
        "Are you sure you want to reset this order?",
        "Confirm",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          updateOrderStatus(orderNumber, "pending");
        })
        .catch(() => {
          ElMessage.info("Action cancelled");
        });
    };

    onMounted(async () => {
      if (!userStore.isLoggedIn) {
        router.push('/login');
        return;
      }

      fetchPendingOrders();
    });

    const disableFutureDates = (time) => {
      return time.getTime() > Date.now();
    };

    const handleStatusChange = (newValue) => {
      console.log("Status changed", newValue);
      fetchPendingOrders();
    };

    const formatDateToView = (row, column, cellValue) => {
      console.log("Cell Value:", cellValue); // 打印日誌
      console.log("Row:", row); // 打印日誌
      console.log("Column:", column); // 打印日誌
      if (!row) return "";
      const date = new Date(row);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      console.log("Formatted Date:", formattedDate); // 打印日誌
      return formattedDate;
    };

    const printReceipt = (orderNumber) => {
      window.open(router.resolve({ name: "PrintReceipt", params: { orderNumber: orderNumber } }).href, '_blank');
    };

    const printMoneyReceipt = (orderNumber, orderType) => {
      if (orderType === 'buy') {
        window.open(router.resolve({ name: "ReceiptBuy", params: { orderNumber: orderNumber } }).href, '_blank');
      } else if (orderType === 'sell') {
        window.open(router.resolve({ name: "ReceiptSell", params: { orderNumber: orderNumber } }).href, '_blank');
      }
    };

    return {
      t,
      orders,
      queryParams,
      total,
      handleEdit,
      formatNumberWithCommas,
      formatNumberWithCommasNoDecimals,
      markAsCompleted,
      closeOrder,
      resetOrder,
      handlePageChange,
      isEditDialogVisible,
      editForm,
      submitEdit,
      disableFutureDates,
      fetchPendingOrders,
      handleStatusChange,
      formatDateToView,
      printReceipt,
      printMoneyReceipt
    };
  },
};
</script>

<style scoped>
.el-button {
  margin-right: 10px;
}

.rice-form-inline .el-select {
  --el-select-width: 160px;
}

.pending-orders {
  padding: 20px;
}

.content-col {
  margin-left: 20px; /* 調整 margin-left */
  margin-right: 20px; /* 調整 margin-right */
}

.order-details {
  border: 1px solid #ebeef5;
  padding: 10px;
  margin-bottom: 10px;
}

.order-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.order-col {
  flex: 1;
  padding: 5px;
}
</style>
